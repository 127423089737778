.tokenCont{
    background: #1e1e1e;
    margin-top: -6%;
    z-index: 999;
    position: relative;
    padding: 40px 0;
}

.tokenCont h2{
    color: #9160e8;
    font-weight: 700;
    transition: linear 0.4s;
}
.cardContent:hover h2{
    color: #fff;
}

.tokenCont h2 span{
    width: 100%;
    display: block;
}

.cardContent {
    text-align: center;
    position: relative;
    min-height: 400px;
    transition: linear 0.4s all;
}

.cardContent p{
    color: #888e9e;
    text-align: center;
    width: 90%;
    margin: 20px auto;
}

.cardContent img{
    width: 20%;
    margin-bottom: 15px;
}

.cardContent:hover:after{
    content: "";
    display: block;
    border: 3px solid #9160e8;
    width: 60px;
    margin: auto;
    position: absolute;
    bottom: 0;
    left: 45%;
    transition: linear 0.4s all;
}