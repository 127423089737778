header {
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #1e1e1ed1;
    position: fixed;
    top: 0;
    z-index: 99999999;
    width: 100%;
    box-shadow: 0px 0px 2px 3px rgba(0, 0, 0, 0.4);
}

.logo {
    width: 6%;
}

header nav ul {
    padding: 0;
    text-decoration: none;
    list-style: none;
    display: flex;
    margin: 0;
    align-items: center;
}

header nav ul li {
    padding: 10px;
    position: relative !important;
}

header nav ul li:hover .dropdown{
    display: block;
}

.dropdown{
    position: absolute !important;
    width: 200px;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    top: 35px;
    background: #000;
    border-radius: 6px;
    display: none;
}

.dropdown li{
    width: 100%;
    padding: 8px 10px !important;
    border-bottom: 1px solid #242424;
}

.dropdown li a{
    position: unset !important;
    text-decoration: none;
    color: #9d9d9d;
    background: unset !important;
    font-weight: 600;
    transition: linear 0.4s all;
}

.dropdown li:hover a{
    color: #a5d7ff;
}

.navBar{
    width: 100%;
    display: flex;
    justify-content: end;
}

.active {
    background: #1e1e1e;
    /* position: fixed; */
}

.customtext {
    color: #9e9aa6;
    font-weight: 600 !important;
    font-size: 15px;
}

.activeLink {
    color: #9160e8;
}

h3.logoText {
    margin: 0;
    font-size: 20px;
    margin-left: 15px;
    font-weight: 600;
    color: #9160e8;
}

.noLink {
    text-decoration: none;
    font-weight: 700;
}

.customLink {
    color: #9160e8;
}

.mobileMenu{
    display: none;
}

.mobile_menuList{
    position: fixed;
    height: 500px;
    top: 58px;
    text-align: center;
    width: 100%;
    left: 0;
    padding: 40px 20px;
    background: #000;
    z-index: 9999999;
}

.mobile_menuList ul{
    padding: 0;
    margin: 0;
    list-style: none;
}

.mobile_menuList ul li{
    margin-bottom: 15px;
}

.mobile_menuList ul li a {
    margin-bottom: 10px;
    font-size: 20px;
}

@media only screen and (max-width: 767px){
    .navBar{
        display: none;
    }
    .mobileMenu{
        display: block;
        color: #9160e8 !important;
    }

    .logo {
        width: 15%;
    }
}

