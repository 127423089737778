.banner_Cont {
    background: #1E1E1E;
    width: 100%;
    display: flex;
    position: relative;
}

.abt {
    width: 90%;
    background: #111824;
    border-radius: 30px;
    margin: 200px auto 50px;
    display: flex;
    justify-content: space-between;
    background-image: url('../../public/images/banner.jpg');
    background-repeat: no-repeat;
    background-size: 100%;
    background-attachment: fixed;
    min-height: 400px;
}

.abt h2 {
    color: #9160e8;
    font-size: 80px;
    font-weight: 900;
    align-items: center;
    display: flex;
    padding-left: 60px;
}

.abt img {
    margin-top: -6%;
}

.abt_content {
    background: #1E1E1E;
    width: 100%;
    padding: 40px 0;
    box-shadow: inset 0px -11px 19px 0px rgba(0, 0, 0, 0.7)
}

.abt_content h4 {
    color: #9160e8;
    line-height: 40px;
    margin-bottom: 50px;
}

.whyUs{
    margin-top: 10%;
}

.whyUs h2{
    color: #9160e8;
    font-weight: 900;
}

.whyUs h4{
    color: #6c6a6a;
    font-weight: 600;
    width: 70%;
    margin: 30px 0;
}

.contentWhyUs{
    background: #1b1b1b;
    padding: 20px;
    border-radius: 20px;
    margin: 0 20px 35px;
    min-height: 300px;
    box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.3);
    transition: 0.4s linear;
}
.contentWhyUs:hover{
    background: #1f1f1f;
}

.contentWhyUs h4{
    color: #fff;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 20px;
    width: 100%;
}


@media only screen and (max-width: 767px) {
    .abt {
        margin: 100px auto 50px;
        background-size: 100% 100%;
    }

    .abt h2 {
        justify-content: center;
        width: 100%;
        font-size: 60px;
        padding-left: 0;
    }

    .banner_Cont img{
        display: none;
    }
}