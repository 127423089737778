.docsContainer {
    background: #000000;
    padding-top: 10%;
    padding-bottom: 50px;
}

.docH1 {
    margin-top: 50px;
    font-weight: 900;
    color: #616161;
    font-size: 30px;
}

.docsContainer span,
.docsContainer p, .docsContainer ol li {
    color: #7b7b7b;
}

.docsContainer ol {
    margin-top: 30px;
}

.docsContainer ol li {
    margin-bottom: 20px;
    text-align: justify;
    text-justify: inter-word;
}

.contentUl{
    margin-top: 20px;
}

.contentUl li{
    font-size: 20px;
    margin-bottom: 20px;
    color: #9160e8;
    font-weight: 700;
}