.supportedCont{
    background: #0c0a1d;
    padding: 50px 0;
}

.itemsList{
    padding: 0;
    margin: 0;
    list-style: none;
    text-decoration: none;
    display: flex;
    justify-content: space-between;
    margin-bottom: 60px;
}

.itemsList li{
    width: 50px;
    fill: #888E9E;
}

.supportedCont h2{
    color: #9160e8;
    font-weight: 900;
    margin-bottom: 10px;
}

.supportedCont p{
    color: #888E9E;
    margin-bottom: 50px;
}

.itemsList li span{
    margin-top: 10px;
    display: block;
    color: #fff;
    font-size: 12px;
    text-align: center;
}

@media only screen and (max-width: 767px) {
    .itemsList {
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .itemsList li{
        width: 60px;
        margin-right: 20px;
        margin-bottom: 15px;
    }
}