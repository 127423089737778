.footerCont {
    padding: 60px 0px;
    background: #14141b;
    border-top: 4px solid #8353e2;
}

.logoFooter {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.logoFooter img {
    width: 20%;
}

.footerNavbar,
.footerEnd {
    width: 20%;
    margin: 30px 0;
    padding: 0;
    display: flex;
    text-decoration: none;
    list-style: none;
    justify-content: space-between;
}

.foot_A {
    text-decoration: none;
    color: #8f8f8f;
    font-weight: 600;
}

.footerEnd {
    width: auto;
}

.footerEnd li {
    margin-right: 20px;
}

.btn_custom {
    background: #9160e8 !important;
    border-radius: 20px !important;
    color: #fff !important;
    font-weight: 600 !important;
    padding: 10px 30px !important;
}

@media only screen and (max-width: 767px) {
    .footerNavbar, .footerEnd {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .footerEnd li, .footerNavbar li {
        margin-right: 0;
        text-transform: uppercase;
        font-size: 14px;
        margin-bottom: 10px;
    }
}