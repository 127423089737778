body{
  font-family: "Raleway", sans-serif !important;
}

.btn_custom{
  background: #9160e8;
  border-radius: 20px;
  color: #fff;
  font-weight: 600;
}
