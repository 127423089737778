
.tokenStory{    
    background: url(../../../public/images/wc1.jpg) no-repeat #1b1b1b;
    background-attachment: fixed;
    min-height: 400px;
    width: 100%;
    background-size: cover;
}

.blackCurtain{
    background: rgba(0, 0, 0, 0.7);
    padding: 90px 0;
}

.blackCurtain h2{
    text-align: center;
    margin-bottom: 60px;
}

.contentWhyUs{
    background: #1b1b1b;
    padding: 20px;
    border-radius: 20px;
    margin: 0 20px 35px;
    min-height: 300px;
    box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.3);
    transition: 0.4s linear;
}
.contentWhyUs:hover{
    background: #1f1f1f;
}

.contentWhyUs h4{
    color: #fff;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 20px;
    width: 100%;
}