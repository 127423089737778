.bannerCont {
    display: flex;
    justify-content: center;
}

.bannerCont img {
    position: relative;
}

.contentData {
    position: absolute;
    top: 35%;
    display: flex;
    flex-wrap: wrap;
    width: 70%;
    color: #fff;
    text-align: center;
    justify-content: center;
}

.contentData h1 {
    font-size: 100px;
    font-weight: 700;
}

.chooseUs {
    padding: 50px 0 170px;
    background: #000;
}

.chooseUs h1 {
    width: 100%;
    text-align: center;
    margin-bottom: 40px;
    font-weight: 900;
    color: #fff;
}

.boxLeft {
    background: #111824;
    border-radius: 20px;
    padding: 40px 0 0px 40px;
    width: 48%;
    overflow: hidden;
}

.headingCont h2 {
    font-size: 24px;
    margin-bottom: 12px;
    font-weight: 700;
    color: #fff;
}

.headingCont p {
    color: #888E9E;
    margin-bottom: 25px;
    line-height: 2;
    font-size: 18px;
    width: 90%;
}

.boxRight {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
}

.cardBox {
    width: 48%;
    background: #c1e6f0;
    border-radius: 20px;
    padding: 30px;
}

.cardBox img {
    margin-bottom: 30px;
}

.cardBox h2 {
    font-weight: 900;
    font-size: 26px;
    margin-bottom: 15px;
    width: 50%;
}

.cardBox p {
    color: #666;
    line-height: 1.7;
}

.rightCont_box {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: relative;
    width: 48%;
}

.boxBottom {
    width: 900px;
    position: absolute;
    bottom: -19%;
    border-radius: 20px;
    overflow: hidden;
    border: 10px solid #000;
}

.btmBxCont {
    background: #d8d7f4;
    padding: 30px;
    display: flex;
}

.btmBxCont h1 {
    text-align: left !important;
    color: #333;
    font-size: 34px;
}

.btmBxCont img {
    width: 31%;
}

.btmBxCont p {
    font-size: 18px;
    line-height: 34px;
}

.whatIsGrt {
    background: #1E1E1E;
    padding: 50px 0;
    position: relative;
}

.whatIsGrt h1 {
    font-size: 48px;
    font-weight: 900;
    width: 50%;
    line-height: 73px;
    color: #9160e8;
}

.whatIsGrt h2 {
    color: #888E9E;
    width: 70%;
    margin-top: 10%;
    font-size: 24px;
    line-height: 45px;
}

.whatIsGrt:after {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    width: 720px;
    height: 720px;
    background: url('../../public/images/bg.svg') top left no-repeat;
}

.h2_heading h2 {
    color: #9160e8;
    font-weight: 900;
    margin-bottom: 30px;
}

.h2_heading p {
    color: #888E9E;
    font-size: 18px;
    line-height: 34px;
}

@media only screen and (max-width: 767px) {

    .neoChoose,
    .neoChoose .rightCont_box .boxRight,
    .neoChoose .boxBottom .btmBxCont {
        flex-direction: column;
    }

    .neoChoose .boxLeft,
    .neoChoose .rightCont_box {
        width: 100%;
    }

    .neoChoose .rightCont_box .cardBox {
        width: 100%;
        margin-bottom: 20px;
        margin-top: 20px;
    }

    .neoChoose .boxBottom {
        border: 0px;
        width: 100%;
        position: unset;
    }

    .neoContent {
        width: 100% !important;
        margin-right: 0 !important;
    }

    .neoContent img, .btmBxCont img {
        display: none;
    }

    .contentData {
        position: absolute;
        z-index: 99999;
        top: 24%;
        width: 90%;
    }

    .contentData h1 {
        font-size: 36px;
    }

    .bannerCont img {
        height: 500px;
    }

    .matButtonCont {
        flex-direction: column;
        width: 100%;
        margin-bottom: 40px;
    }

    .matButtonCont button {
        margin-bottom: 20px;
        margin-right: 0 !important;
    }

    .tokenCont {
        margin-top: 0;
        padding: 80px 0 40px 0;
    }

    .cardContent {
        min-height: auto;
        margin-bottom: 20px;
    }

    .whatIsGrt h1 {
        font-size: 38px;
        width: 100%;
        line-height: 50px;
    }

    .whatIsGrt h2 {
        color: #cbcfd7;
        width: 100%;
        font-size: 18px;
        line-height: 36px;
    }
}